export default [{
        path: "/MaterialModule/Material/List",
        name: "NewMaterial_List",
        component: () =>
            import ("@/Views/MaterialModule/Material/List.vue"),
        children: []
    },
    {
        path: "/MaterialModule/Material/Add",
        name: "NewMaterial_Add",
        component: () =>
            import ("@/Views/MaterialModule/Material/Add.vue"),
        children: []
    },
    {
        path: "/MaterialModule/Material/MomentDetail",
        name: "NewMaterial_MomentDetail",
        component: () =>
            import ("@/Views/MaterialModule/Material/MomentDetail.vue"),
        children: []
    },
    {
        path: "/MaterialModule/Material/ExtensionDetail",
        name: "NewMaterial_ExtensionDetail",
        component: () =>
            import ("@/Views/MaterialModule/Material/ExtensionDetail.vue"),
        children: []
    },
    {
        path: "/MaterialModule/Store/List",
        name: "Store_List",
        component: () =>
            import ("@/Views/MaterialModule/Store/List.vue"),
        children: []
    },
    {
        path: "/MaterialModule/Store/Detail",
        name: "Store_Detail",
        component: () =>
            import ("@/Views/MaterialModule/Store/Detail.vue"),
        children: []
    },
    {
        path: "/MaterialModule/Material/H5",
        name: "NewMaterial_H5",
        component: () =>
            import ("@/Views/MaterialModule/Material/H5.vue"),
        children: []
    },
    {
        path: "/MaterialModule/Material/ReadList",
        name: "Material_ReadList",
        component: () =>
            import ("@/Views/MaterialModule/Material/ReadList.vue"),
        children: []
    },
    {
        path: "/MaterialModule/Store/MaterialList",
        name: "Store_MaterialList",
        component: () =>
            import ("@/Views/MaterialModule/Store/MaterialList.vue"),
        children: []
    },
    {
        path: "/MaterialModule/Store/PackageList",
        name: "Store_PackageList",
        component: () =>
            import ("@/Views/MaterialModule/Store/PackageList.vue"),
        children: []
    },
    {
        path: "/MaterialModule/Store/PushList",
        name: "Store_PushList",
        component: () =>
            import ("@/Views/MaterialModule/Store/PushList.vue"),
        children: []
    },
    {
        path: "/MaterialModule/Store/PackageHistoryList",
        name: "Store_PackageHistoryList",
        component: () =>
            import ("@/Views/MaterialModule/Store/PackageHistoryList.vue"),
        children: []
    },
    {
        path: "/MaterialModule/Store/MyList",
        name: "Store_MyList",
        component: () =>
            import ("@/Views/MaterialModule/Store/MyList.vue"),
        children: []
    },
    {
        path: "/MaterialModule/Store/PackageDetail",
        name: "Store_PackageDetail",
        component: () =>
            import ("@/Views/MaterialModule/Store/PackageDetail.vue"),
        children: []
    },
    {
        path: "/MaterialModule/M2Material/Add",
        name: "M2Material_Add",
        component: () =>
            import ("@/Views/MaterialModule/M2Material/Add.vue"),
        children: []
    },
    {
        path: "/MaterialModule/components/H5editor",
        name: "M2Material_H5editor",
        component: () =>
            import ("@/Views/MaterialModule/components/H5editor.vue"),
        children: []
    },
    {
        path: "/MaterialModule/components/Moment",
        name: "M2Material_Moment",
        component: () =>
            import ("@/Views/MaterialModule/components/Moment.vue"),
        children: []
    },
    {
        path: "/MaterialModule/M2Material/Detail",
        name: "M2Material_Detail",
        component: () =>
            import ("@/Views/MaterialModule/M2Material/Detail.vue"),
        children: []
    },
    {
        path: "/MaterialModule/components/Extension",
        name: "M2Material_Extension",
        component: () =>
            import ("@/Views/MaterialModule/components/Extension.vue"),
        children: []
    },
    {
        path: "/MaterialModule/components/H5",
        name: "M2Material_H5",
        component: () =>
            import ("@/Views/MaterialModule/components/H5.vue"),
        children: []
    },
    {
        path: "/MaterialModule/Store/MaterialAwardDetail",
        name: "Store_MaterialAwardDetail",
        component: () =>
            import ("@/Views/MaterialModule/Store/MaterialAwardDetail.vue"),
        children: []
    },
    {
        path: "/MaterialModule/Store/PartnerList",
        name: "Store_PartnerList",
        component: () =>
            import ("@/Views/MaterialModule/Store/PartnerList.vue"),
        children: []
    },
    {
        path: "/MaterialModule/Store/MaterialTabs",
        name: "Store_MaterialTabs",
        component: () =>
            import ("@/Views/MaterialModule/Store/MaterialTabs.vue"),
        children: []
    },
    {
        path: "/MaterialModule/Material/MaterialList",
        name: "Material_MaterialList",
        component: () =>
            import ("@/Views/MaterialModule/Material/MaterialList.vue"),
        children: []
    },
    {
        path: "/MaterialModule/Store/MyList?MaterialType=3",
        name: "Store_MyList?MaterialType=3",
        component: () =>
            import ("@/Views/MaterialModule/Store/MyList.vue?MaterialType=3"),
        children: []
    },
    {
        path: "/MaterialModule/Store/MyList?MaterialType=1",
        name: "Store_MyList?MaterialType=1",
        component: () =>
            import ("@/Views/MaterialModule/Store/MyList.vue?MaterialType=1"),
        children: []
    },
    {
        path: "/MaterialModule/Store/MyList?MaterialType=0",
        name: "Store_MyList?MaterialType=0",
        component: () =>
            import ("@/Views/MaterialModule/Store/MyList.vue?MaterialType=0"),
        children: []
    },
    {
        path: "/MaterialModule/Store/PrizeList",
        name: "Material_PrizeList",
        component: () =>
            import ("@/Views/MaterialModule/Store/PrizeList.vue"),
        children: []
    },
    {
        path: "/MaterialModule/Store/PrizeDetail",
        name: "Material_PrizeDetail",
        component: () =>
            import ("@/Views/MaterialModule/Store/PrizeDetail.vue"),
        children: []
    },
    {
        path: "/MaterialModule/components/Poster",
        name: "M2Material_Poster",
        component: () =>
            import ("@/Views/MaterialModule/components/Poster.vue"),
        children: []
    },
    {
        path: "/MaterialModule/components/AwardPool",
        name: "M2Material_AwardPool",
        component: () =>
            import ("@/Views/MaterialModule/components/AwardPool.vue"),
        children: []
    },
    {
        path: "/MaterialModule/Store/PrizeSendDetail",
        name: "M2Material_PrizeSendDetail",
        component: () =>
            import ("@/Views/MaterialModule/Store/PrizeSendDetail.vue"),
        children: []
    },
    {
        path: "/InteractionClockModule/PersonalList",
        name: "PersonalModule_List",
        component: () =>
            import ("@/Views/InteractionClockModule/PersonalList.vue"),
        children: []
    },

    {
        path: "/MaterialModule/Store/SubscribeList",
        name: "Store_SubscribeList",
        component: () =>
            import ("@/Views/MaterialModule/Store/SubscribeList.vue"),
        children: []
    },
    {
        path: "/MaterialModule/Store/PackageList?MaterialType=3",
        name: "Store_PackageList?MaterialType=3",
        component: () =>
            import ("@/Views/MaterialModule/Store/PackageList.vue?MaterialType=3"),
        children: []
    },
    {
        path: "/MaterialModule/Store/PackageList?MaterialType=5",
        name: "Store_PackageList?MaterialType=5",
        component: () =>
            import ("@/Views/MaterialModule/Store/PackageList.vue?MaterialType=5"),
        children: []
    },
    {
        path: "/MaterialModule/Store/MyListShow",
        name: "Store_MyListShow",
        component: () =>
            import ("@/Views/MaterialModule/Store/MyListShow.vue"),
        children: []
    },
    {
        path: "/MaterialModule/components/NewPoster",
        name: "Store_NewPoster",
        component: () =>
            import ("@/Views/MaterialModule/components/NewPoster.vue"),
        children: []
    },
    {
        path: "/MaterialModule/components/NewH5",
        name: "Store_NewH5",
        component: () =>
            import ("@/Views/MaterialModule/components/NewH5.vue"),
        children: []
    },
    {
        path: "/MaterialModule/components/AddExtension",
        name: "M2Material_AddExtension",
        component: () =>
            import ("@/Views/MaterialModule/components/AddExtension.vue"),
        children: []
    },
    {
        path: "/MaterialModule/Store/DefaultConfiguration",
        name: "Store_DefaultConfiguration",
        component: () =>
            import ("@/Views/MaterialModule/Store/DefaultConfiguration.vue"),
        children: []
    }

]