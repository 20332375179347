export default [
    {
        path: "/Ranking/VisitRanking",
        name: "VisitRanking",
        component: () => import("@/Views/Ranking/VisitRanking.vue"),
        children: []
    },
    {
        path: "/Ranking/MaterialRanking",
        name: "MaterialRanking",
        component: () => import("@/Views/Ranking/MaterialRanking.vue"),
        children: []
    },
    {
        path: "/Ranking/IntentionConsultation",
        name: "IntentionConsultation",
        component: () => import("@/Views/Ranking/IntentionConsultation.vue"),
        children: []
    },
    {
        path: "/Ranking/PlatformDetails",
        name: "PlatformDetails",
        component: () => import("@/Views/Ranking/PlatformDetails.vue"),
        children: []
    },
    {
        path: "/Ranking/MemberInformatio",
        name: "MemberInformatio",
        component: () => import("@/Views/Ranking/MemberInformatio.vue"),
        children: []
    }
]