const isValidDate = date => {
    return date instanceof Date && !isNaN(date.getTime());
}
const strToDateTime = str => {
    if (isBlank(str))
        return null;
    var s = str.replace(/-/g, '/').replace(/T/g, ' ').split('.')[0];
    return new Date(s);
}
const formatDateTime = date => {
    if (!isValidDate(date)) return '';
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();
    return [year, month, day].map(formatNumber).join('-') + ' ' + [hour, minute, second].map(formatNumber).join(':');
}
const formatTime = date => {
    if (!isValidDate(date)) return '';
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();
    return [hour, minute, second].map(formatNumber).join(':');
}
const formatTime2 = date => {
    if (!isValidDate(date)) return '';
    const hour = date.getHours();
    const minute = date.getMinutes();
    return [hour, minute].map(formatNumber).join(':');
}
var DATE_FORMAT = /^[0-9]{4}-[0-1]?[0-9]{1}-[0-3]?[0-9]{1}$/;
const isValidDate2 = date => {
    if (DATE_FORMAT.test(date)) {
        return date
    } else {
        return date instanceof Date && !isNaN(date.getTime());
    }
}
const formatDate = date => {
    if (!isValidDate2(date)) return '';
    if (DATE_FORMAT.test(date)) {
        return date
    } else {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        return [year, month, day].map(formatNumber).join('-');
    }
}
const formatDate2 = date => {
    if (!isValidDate(date)) return '';
    const year = formatNumber(date.getFullYear());
    const month = formatNumber(date.getMonth() + 1);
    const day = formatNumber(date.getDate());
    return [year, "年", month, "月", day, "日"].join("");
}
const formatDate3 = date => {

    if (!isValidDate(date)) return '';
    const year = date.getFullYear(); //得到当前年份
    const month = date.getMonth() + 1; //得到当前月份
    const day = date.getDate(); //得到当前几号
    const hour = date.getHours(); //得到当前小时
    const minute = date.getMinutes(); //得到当前分钟
    const second = date.getSeconds(); //得到当前秒
    return [year, month, day].map(formatNumber).join('-') + " " + [hour, minute, second].map(formatNumber).join(':')
}
const formatMonth = date => {
    if (!isValidDate(date)) return '';
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    return [year, month].map(formatNumber).join('-');
}
const formatMonth2 = date => {
    if (!isValidDate(date)) return '';
    const year = formatNumber(date.getFullYear());
    const month = formatNumber(date.getMonth() + 1);
    return [year, "年", month, "月"].join("");
}
const GetToday = date => {
    if (!isValidDate(date)) date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    return {
        startdate: new Date(year, month, day),
        enddate: new Date(year, month, day)
    };
}
const GetWeek = (date, type) => {
    if (!isValidDate(date)) date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const d = date.getDate();
    var day = date.getDay();
    if (type != 'FromSunday') {
        if (day == 0) day = 6;
        else day = day - 1;
    }
    return {
        startdate: new Date(year, month, d - day),
        enddate: new Date(year, month, d - day + 6)
    };
}
const GetMonth = date => {
    if (!isValidDate(date)) date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const NextMonth = new Date(year, month + 1, 1);
    const lastDate = NextMonth.setDate(-1);
    return {
        startdate: new Date(year, month, 1),
        enddate: new Date(lastDate)
    };
}
const GetYear = date => {
    if (!isValidDate(date)) date = new Date();
    const year = date.getFullYear();
    const NextYear = new Date(year + 1, 0, 1);
    const lastDate = NextYear.setDate(-1);
    return {
        startdate: new Date(year, 0, 1),
        enddate: new Date(lastDate)
    };
}
const GetUTC = date => {
    if (!isValidDate(date)) date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();
    const millisecond = date.getMilliseconds();
    return Date.UTC(year, month, day, hour, minute, second, millisecond);
}
const formatNumber = n => {
    n = n.toString()
    return n[1] ? n : '0' + n
}

function stringToBoolean(str) {
    switch (str.toLowerCase()) {
        case "true":
        case "yes":
        case "1":
            return true;
        case "false":
        case "no":
        case "0":
        case null:
            return false;
        default:
            return Boolean(str);
    }
}

function isBlank(str) {
    if (Object.prototype.toString.call(str) === '[object Undefined]') { //空
        return true;
    } else if (Object.prototype.toString.call(str) === '[object Null]') { //空
        return true;
    } else if (Object.prototype.toString.call(str) === '[object String]') { //字条串
        return str.length == 0 ? true : false;
    } else if (Object.prototype.toString.call(str) === '[object Array]') { //数组
        return str.length == 0 ? true : false;
    } else if (Object.prototype.toString.call(str) === '[object Object]') { //对象
        return JSON.stringify(str) == '{}' ? true : false;
    } else {
        return false;
    }
}

function ToFixed(num, mark = '￥') {
    return mark + Number(num).toFixed(2);
}

function TimeStamptoDateTime(timeSpan, format) {
    var dateTime = new Date(parseInt(timeSpan.substring(6, timeSpan.length - 2)));
    format = format.replace("yyyy", dateTime.getFullYear());
    format = format.replace("yy", Complement(dateTime.getFullYear().toString().substr(2)));
    format = format.replace("MM", Complement(dateTime.getMonth() + 1));
    format = format.replace("dd", Complement(dateTime.getDate()));
    format = format.replace("hh", Complement(dateTime.getHours()));
    format = format.replace("mm", Complement(dateTime.getMinutes()));
    format = format.replace("ss", Complement(dateTime.getSeconds()));
    format = format.replace("ms", Complement(dateTime.getMilliseconds()))
    return format;
}

function Complement(num) {
    if (parseInt(num) < 10) {
        num = '0' + num;
    }
    return num;
}
var regexp = /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}/;

function GetPhone(Phone) {
    var num = '';
    if (regexp.test(Phone)) {
        num = Phone;
    }
    return num;
}

function getFlatternDistance(latitude1, longitude1, latitude2, longitude2) {
    if ((latitude1 === latitude2) && (longitude1 === longitude2)) {
        return 0;
    }
    var lat1 = latitude1 - 0;
    var lng1 = longitude1 - 0;
    var lat2 = latitude2 - 0;
    var lng2 = longitude2 - 0;

    if (lat1 == null || lng1 == null ||
        lat2 == null || lng2 == null) {
        return Number.NaN;
    }
    var f = getRad((lat1 + lat2) / 2);
    var g = getRad((lat1 - lat2) / 2);
    var l = getRad((lng1 - lng2) / 2);

    var sg = Math.sin(g);
    var sl = Math.sin(l);
    var sf = Math.sin(f);

    var s, c, w, r, d, h1, h2;
    var a = EARTH_RADIUS;
    var fl = 1 / 298.257;

    sg = sg * sg;
    sl = sl * sl;
    sf = sf * sf;

    s = sg * (1 - sl) + (1 - sf) * sl;
    c = (1 - sg) * (1 - sl) + sf * sl;

    w = Math.atan(Math.sqrt(s / c));
    r = Math.sqrt(s * c) / w;
    d = 2 * w * a;
    h1 = (3 * r - 1) / 2 / c;
    h2 = (3 * r + 1) / 2 / s;

    var tValue = d * (1 + fl * (h1 * sf * (1 - sg) - h2 * (1 - sf) * sg));
    return tValue;
}

function getRad(d) {
    return d * PI / 180.0;
}

function SubtractDateTime(date1, date2) {
    // var date1= '2018-11-30 11:39:10';  //开始时间
    // var date2 = new Date();    //结束时间
    const date3 = new Date(date2).getTime() - new Date(date1).getTime() //时间差的毫秒数
        //计算出相差天数
    const days = Math.floor(date3 / (24 * 3600 * 1000))
        //计算出小时数
        //const leave1 = date3 % (24 * 3600 * 1000) //计算天数后剩余的毫秒数
        //const hours = Math.floor(leave1 / (3600 * 1000))
        //计算相差分钟数
        //const leave2 = leave1 % (3600 * 1000) //计算小时数后剩余的毫秒数
        //const minutes = Math.floor(leave2 / (60 * 1000))
        //计算相差秒数
        //const leave3 = leave2 % (60 * 1000) //计算分钟数后剩余的毫秒数
        //const seconds = Math.round(leave3 / 1000)
    let diffValue
    if (days > 0) {
        diffValue = days
    }
    return diffValue
}
//返回两日期相差天数、月数
function getDateNum(date1, date2, type) {
    const date3 = new Date(date2).getTime() - new Date(date1).getTime() //时间差的毫秒数
        //计算出相差天数
        //两个日期的毫秒差/（24*3600*1000） 得出来的就是两个日期相差的天数
    const day_num = Math.floor(date3 / (24 * 3600 * 1000))
        //（（data2的年分-data1的年份）* 12+（data2的月份-data1的月份））+1 就等于两个日期相差的月数 
        //计算出相差月数
    const month_num = (parseInt(date2.split('-')[0]) - parseInt(date1.split('-')[0])) * 12 + (parseInt(date2.split('-')[1]) - parseInt(date1.split('-')[1])) + 1;
    let retu_num;
    switch (type) {
        case 'day':
            retu_num = day_num;
            break;
        case 'month':
            retu_num = month_num;
            break;
        default:
            retu_num;
            break;
    }
    return retu_num;
}

//用于首页日期拆分
function handleMonths(yearMonthDay, i) {
    var arr = yearMonthDay.split('-'); //2020-08-19或2020-08
    var year = parseInt(arr[0]);
    var month = parseInt(arr[1]);
    var day = parseInt(arr[2]);
    if (i != 1) {
        month = month + 1;
    }
    if (month > 12) { //月份加
        let yearNum = parseInt((month - 1) / 12);
        month = month % 12 == 0 ? 12 : month % 12;
        year += yearNum;
    } else if (month <= 0) { //月份减
        month = Math.abs(month);
        let yearNum = parseInt((month + 12) / 12);
        year -= yearNum;
    }
    month = month < 10 ? "0" + month : month;
    //根据年月判断改年月获取改年月的最后一天
    var day_year = new Date(year, month, "01");
    day_year.setDate(1);
    day_year.setMonth(day_year.getMonth());
    //获取本月的最后一天 
    day = new Date(day_year.getTime() - 1000 * 60 * 60 * 24).getDate() - 1;
    let day1 = new Date(day_year.getTime() - 1000 * 60 * 60 * 24).getDate();
    day = day < 10 ? "0" + day : day;
    let lastdate = year + "-" + month + "-" + day;
    let onedate = year + "-" + month + "-" + day1;
    return lastdate + "&" + onedate;
}
//通用：根据日期获取该日期本月的最后一天的日期
function AllhandleMonths(yearMonthDay) {
    var arr = yearMonthDay.split('-'); //2020-08-19或2020-08
    var year = parseInt(arr[0]);
    var month = parseInt(arr[1]);
    var day = parseInt(arr[2]);
    month = month + 1
    if (month > 12) { //月份加
        let yearNum = parseInt((month - 1) / 12);
        month = month % 12 == 0 ? 12 : month % 12;
        year += yearNum;
    } else if (month <= 0) { //月份减
        month = Math.abs(month);
        let yearNum = parseInt((month + 12) / 12);
        year -= yearNum;
    }
    month = month < 10 ? "0" + month : month;
    //根据年月判断改年月获取改年月的最后一天
    var day_year = new Date(year, month, "01");
    day_year.setDate(1);
    day_year.setMonth(day_year.getMonth());
    //获取本月的最后一天 
    day = new Date(day_year.getTime() - 1000 * 60 * 60 * 24).getDate();
    day = day < 10 ? "0" + day : day;
    return year + "-" + month + "-" + day;
}

function ChangeDateFormat(jsondate) {
    jsondate = jsondate.replace("/Date(", "").replace(")/", "");
    if (jsondate.indexOf("+") > 0) {
        jsondate = jsondate.substring(0, jsondate.indexOf("+"));
    } else if (jsondate.indexOf("-") > 0) {
        jsondate = jsondate.substring(0, jsondate.indexOf("-"));
    }
    var date = new Date(parseInt(jsondate, 10));
    var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
    var currentDate = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    var hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var second = date.getMilliseconds() / 1000 < 10 ? "0" + parseInt(date.getMilliseconds() / 1000) : parseInt(date.getMilliseconds() / 1000);
    return date.getFullYear() + "-" + month + "-" + currentDate + " " + hours + ":" + minutes + ":" + second;
}

function ReturnTenDay(dateTime, count) {
    var nowTimeStem = new Date(dateTime).getTime();
    var endTimeStem = nowTimeStem + 24 * 60 * 60 * 1000 * parseInt(count);
    var endDateObj = new Date(endTimeStem);
    var month = endDateObj.getMonth() + 1;
    month = month >= 10 ? month : '0' + month;
    var day = endDateObj.getDate();
    day = day >= 10 ? day : '0' + day;
    var endDateStr = endDateObj.getFullYear() + '-' + month + '-' + day;
    return endDateStr; //y + "-" + m + "-" + d;

}

//地球半径
const EARTH_RADIUS = 6378137.0; //单位M
const PI = 3.141592653589793;
export default {
    formatTime,
    formatTime2,
    formatDateTime,
    formatDate,
    formatDate2,
    formatDate3,
    formatMonth,
    formatMonth2,
    GetToday,
    GetWeek,
    GetMonth,
    GetYear,
    GetUTC,
    isBlank,
    strToDateTime,
    stringToBoolean,
    ToFixed,
    TimeStamptoDateTime,
    GetPhone,
    getFlatternDistance,
    SubtractDateTime,
    ChangeDateFormat,
    getDateNum,
    handleMonths,
    AllhandleMonths,
    ReturnTenDay
}