export default [
    {
        path: "/InteractionClockModule/List",
        name: "InteractionClockModule_List",
        component: () => import("@/Views/InteractionClockModule/List.vue"),
        children: []
    },

    {
        path: "/InteractionClockModule/PersonalList",
        name: "PersonalModule_List",
        component: () => import("@/Views/InteractionClockModule/PersonalList.vue"),
        children: []
    },

    {
        path: "/InteractionClockModule/ClockInDetail",
        name: "ClockInDetail_List",
        component: () => import("@/Views/InteractionClockModule/ClockInDetail.vue"),
        children: []
    },

    {
        path: "/MaterialModule/components/NewPoster",
        name: "Store_NewPoster",
        component: () => import("@/Views/MaterialModule/components/NewPoster.vue"),
        children: []
    },

]