export default [
    {
        path: "/Error/403",
        name: "403",
        component: () => import("@/ErrorPage/403.vue"),
        children: []
    },
    {
        path: "/Error/404",
        name: "404",
        component: () => import("@/ErrorPage/404.vue"),
        children: []
    },
    {
        path: "/Error/500",
        name: "500",
        component: () => import("@/ErrorPage/500.vue"),
        children: []
    }
]