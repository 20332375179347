/* 封装全局提示信息队列，避免同一个信息同时弹出多个。
 * 
 */
import util from '@/Plugin/util.js'
import { message } from 'ant-design-vue'
let Message = {
};
let MessageQueue = [];//消息队列
let MessageShowing = [];//正在显示的消息列表
let isRun = false;
function MessageShow() {
    if (isRun)//添加标识，避免并发
        return;
    isRun = true;
    //当消息队列不为空时
    while (MessageQueue.length > 0) {
        //从队列中取出第一个消息
        var temp = MessageQueue.shift();
        //在显示的消息列表中查找
        var temp2 = MessageShowing.find(function (value) {
            if (value.type == temp.type && value.content == temp.content) {
                return value;
            }
        });
        //如果正在显示的消息列表中没有此消息
        if (util.isBlank(temp2)) {
            //添加正在显示的列表
            MessageShowing.push(temp); 
            //显示消息
            message[temp.type](temp.content, function () {
                //回调，当消息显示框关闭时，从正在显示的消息列表中移除
                var index = MessageShowing.find(function (value, index) {
                    if (value.type == temp.type && value.content == temp.content) {
                        return index;
                    }
                });
                MessageShowing.splice(index, 1);
            });
        }
    }
    isRun = false;
}
function ShowMessage(type, content) {
    MessageQueue.push({ type, content }); //插入到消息队列等待显示
    MessageShow(); 
}
['error', 'success', 'info', 'warning'].forEach(type => {
    Message[type] = function (content) {
        return ShowMessage(type, content);
    };
});

export default Message