import http from "@/Plugin/Http.js";
import util from "@/Plugin/util.js";
import Vue from 'vue'
import { message } from 'ant-design-vue'
var Empty = {
    UserID:"",
    UserName: "",
    CustomerID: "",
    CustomerName: "",
    UserLoginName: "",
}
var gohome = function () {
    return Vue.global.NavigateTo("HomeIndex"),1;
}
function Logout() {
    var op = {
        url: "/Account/Logout",
        data: {},
        OnSuccess: function () {
            Vue.global.NavigateTo("Login");
            Vue.global.Store.SetCache(Vue.global.Store.CacheName.CurrentUserInfo, Empty);
        }
    };
    http.Post(op);
}
function GetCurrentUserInfo(callback) {
    var CurrentUserInfo = Vue.global.Store.GetCache(Vue.global.Store.CacheName.CurrentUserInfo)
    var op = {
        url: "/Account/GetCurrentUserInfo",
        data: {},
        OnSuccess: function (response) {
            Vue.global.Store.SetCache(Vue.global.Store.CacheName.CurrentUserInfo, response.data);
            CurrentUserInfo = Vue.global.Store.GetCache(Vue.global.Store.CacheName.CurrentUserInfo);
            callback(CurrentUserInfo);
        }
    };
    if (!CurrentUserInfo) {
        http.Post(op);
    }
    else if (util.isBlank(CurrentUserInfo.UserID)) {
        http.Post(op);
    }
    else
        callback(CurrentUserInfo);
}
function Login(username, password, MessageShow) {
    Vue.global.Store.SetCache(Vue.global.Store.CacheName.CurrentUserInfo, Empty);
    var op = {
        url: "/Account/LoginCheck",
        data: {
            UserName: username,
            Password: password
        },
        OnSuccess: function (response) {           
            response.data && gohome() ||
                (typeof MessageShow == "function" && (MessageShow("账号或密码错误"), 1) || message.error("账号或密码错误"));  
        }
    };
    if (typeof MessageShow == "function") {
        op.ErrorMessageShow = MessageShow;
    }
    http.Post(op);
}

function LoginHome() {
    Vue.global.Store.SetCache(Vue.global.Store.CacheName.CurrentUserInfo, Empty);
    gohome();
}
export default {
    Logout,
    Login,
    GetCurrentUserInfo,
    LoginHome
}