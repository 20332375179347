export default [
    {
        path: "/InteractiveModel/Index", 
        name: "InteractiveModelIndex",
        component: () => import("@/Views/InteractiveModel/Index.vue"),
        children: []
    },
     {
         path: "/InteractiveModel/ViewDetails",
         name: "InteractiveModelViewDetails",
         component: () => import("@/Views/InteractiveModel/ViewDetails.vue"),
        children: []
    }
]