import Account from "@/Plugin/Account.js";
import http from "@/Plugin/Http.js";
function Logout() {
    Account.Logout();
} 
function Run(name) {
    eval(name);
}
//�ͷ��˵��´�����ת
function CServer() {
    var op = {
        url: "/OperationsModule/Operations/GetPCLoginURL",
        data: {
        },
        OnSuccess: function (data) {
            console.log(data.data);
            window.open(data.data);
        }
    };
    http.Post(op);    
}
export default {
    Logout,
    Run,
    CServer
}