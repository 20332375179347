import Vue from 'vue'
import App from './App.vue'
import router from "./router"
import Store from "@/Plugin/Store.js"
import util from "@/Plugin/util.js"
import "./Plugin/ele.js"
Vue.config.productionTip = false
Vue.global = {
    NavigateTo: function (RouterName) {
        if (util.isBlank(RouterName)) return;
        if (Vue.global.VueRoot.$route.name == RouterName) return;
        else Vue.global.VueRoot.$router.push({ name: RouterName });
    },
    GetCurrentRouteName: function () {
        return Vue.global.VueRoot.$route.name;
    }
}
Vue.global.Store = Store;
Vue.global.VueRoot = new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
