export default [
    {
        path: "/TaskManageModule/Task/List",
        name: "Task_List",
        component: () => import("@/Views/TaskManageModule/Task/List.vue"),
        children: []
    },
    {
        path: "/TaskManageModule/Task/NewTaskBase",
        name: "Task_NewTaskBase",
        component: () => import("@/Views/TaskManageModule/Task/NewTaskBase.vue"),
        children: []
    },
    {
        path: "/TaskManageModule/Task/NewTaskTarget",
        name: "Task_NewTaskTarget",
        component: () => import("@/Views/TaskManageModule/Task/NewTaskTarget.vue"),
        children: []
    },
    {
        path: "/TaskManageModule/Task/UserTask",
        name: "Task_UserTask",
        component: () => import("@/Views/TaskManageModule/Task/UserTask.vue"),
        children: []
    }
]