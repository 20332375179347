export default [
    {
        path: "/MaterialSourceModule/Material/List",
        name: "Material_List",
        component: () => import("@/Views/MaterialSourceModule/Material/List.vue"),
        children: []
    },
    {
        path: "/MaterialSourceModule/Material/Add",
        name: "Material_Add",
        component: () => import("@/Views/MaterialSourceModule/Material/Add.vue"),
        children: []
    },
    {
        path: "/MaterialSourceModule/Material/MomentDetail",
        name: "Material_MomentDetail",
        component: () => import("@/Views/MaterialSourceModule/Material/MomentDetail.vue"),
        children: []
    },
    {
        path: "/MaterialSourceModule/Material/ExtensionDetail",
        name: "Material_ExtensionDetail",
        component: () => import("@/Views/MaterialSourceModule/Material/ExtensionDetail.vue"),
        children: []
    }

]