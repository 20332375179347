export default [
    {
        path: "/SystemModule/EditPassword",
        name: "EditPassword",
        component: () => import("@/Views/SystemModule/EditPassword.vue"),
        children: []
    },
    {
        path: "/SystemModule/EditBaseInfor",
        name: "EditBaseInfor",
        component: () => import("@/Views/SystemModule/EditBaseInfor.vue"),
        children: []
    }
]