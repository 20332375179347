export default [{
        path: "/PromoteModule/PromoteStimulateSharerConfig/List",
        name: "PromoteStimulateSharerConfig_List",
        component: () =>
            import ("@/Views/PromoteModule/PromoteStimulateSharerConfig/List.vue"),
        children: []
    },
    {
        path: "/PromoteModule/PromoteStimulateSharerConfig/Detail",
        name: "PromoteStimulateSharerConfig_Detail",
        component: () =>
            import ("@/Views/PromoteModule/PromoteStimulateSharerConfig/Detail.vue"),
        children: []
    },
    {
        path: "/PromoteModule/PromoteStimulatePartnerConfig/List",
        name: "PromoteStimulatePartnerConfig_List",
        component: () =>
            import ("@/Views/PromoteModule/PromoteStimulatePartnerConfig/List.vue"),
        children: []
    },
    {
        path: "/PromoteModule/PromoteStimulateSharerConfig/DetailInfo",
        name: "PromoteStimulateSharerConfig_DetailInfo",
        component: () =>
            import ("@/Views/PromoteModule/PromoteStimulateSharerConfig/DetailInfo.vue"),
        children: []
    },
    {
        path: "/PromoteModule/PromoteStimulateRedEnvelopesConfig/List",
        name: "PromoteStimulateRedEnvelopesConfig_List",
        component: () =>
            import ("@/Views/PromoteModule/PromoteStimulateRedEnvelopesConfig/List.vue"),
        children: []
    },
    {
        path: "/PromoteModule/PromoteStimulateRedEnvelopesConfig/Detail",
        name: "PromoteStimulateRedEnvelopesConfig_Detail",
        component: () =>
            import ("@/Views/PromoteModule/PromoteStimulateRedEnvelopesConfig/Detail.vue"),
        children: []
    },
    {
        path: "/PromoteModule/PromoteStimulateRedEnvelopesConfig/DetailInfo",
        name: "PromoteStimulateRedEnvelopesConfig_DetailInfo",
        component: () =>
            import ("@/Views/PromoteModule/PromoteStimulateRedEnvelopesConfig/DetailInfo.vue"),
        children: []
    },

    {
        path: "/PromoteModule/PromoteStimulateTeamRedEnvelopesConfig/List",
        name: "PromoteStimulateTeamRedEnvelopesConfig_List",
        component: () =>
            import ("@/Views/PromoteModule/PromoteStimulateTeamRedEnvelopesConfig/List.vue"),
        children: []
    },

    {
        path: "/PromoteModule/PromoteStimulateTeamRedEnvelopesConfig/Detail",
        name: "PromoteStimulateTeamRedEnvelopesConfig_Detail",
        component: () =>
            import ("@/Views/PromoteModule/PromoteStimulateTeamRedEnvelopesConfig/Detail.vue"),
        children: []
    },
    {
        path: "/PromoteModule/PromoteStimulateTeamRedEnvelopesConfig/DetailInfo",
        name: "PromoteStimulateTeamRedEnvelopesConfig_DetailInfo",
        component: () =>
            import ("@/Views/PromoteModule/PromoteStimulateTeamRedEnvelopesConfig/DetailInfo.vue"),
        children: []
    },
    {
        path: "/PromoteModule/PromoteStimulateTeamRedEnvelopesConfig/RankingInfo",
        name: "PromoteStimulateTeamRedEnvelopesConfig_RankingInfo",
        component: () =>
            import ("@/Views/PromoteModule/PromoteStimulateTeamRedEnvelopesConfig/RankingInfo.vue"),
        children: []
    },

    {
        path: "/PromoteModule/InteractionConfig/List",
        name: "InteractionConfig_List",
        component: () =>
            import ("@/Views/PromoteModule/InteractionConfig/List.vue"),
        children: []
    },

    {
        path: "/PromoteModule/InteractionConfig/DetailInfo",
        name: "InteractionConfig_DetailInfo",
        component: () =>
            import ("@/Views/PromoteModule/InteractionConfig/DetailInfo.vue"),
        children: []
    },
    {
        path: "/PromoteModule/InteractionConfig/HelpAndTeamPersonalInfo",
        name: "InteractionConfig_HelpAndTeamPersonalInfo",
        component: () =>
            import ("@/Views/PromoteModule/InteractionConfig/HelpAndTeamPersonalInfo.vue"),
        children: []
    },
    {
        path: "/PromoteModule/InteractionConfig/AddInteraction",
        name: "InteractionConfig_AddInteraction",
        component: () =>
            import ("@/Views/PromoteModule/InteractionConfig/AddInteraction.vue"),
        children: []
    },
    {
        path: "/PromoteModule/InteractionConfig/AddInteractionAward",
        name: "InteractionConfig_AddInteractionAward",
        component: () =>
            import ("@/Views/PromoteModule/InteractionConfig/AddInteractionAward.vue"),
        children: []
    },
    {
        path: "/PromoteModule/InteractionConfig/InteractionPay",
        name: "InteractionConfig_InteractionPay",
        component: () =>
            import ("@/Views/PromoteModule/InteractionConfig/InteractionPay.vue"),
        children: []
    },

    {
        path: "/PromoteModule/PromoteMaterial/Add",
        name: "PromoteMaterial_Add",
        component: () =>
            import ("@/Views/PromoteModule/PromoteMaterial/Add.vue"),
        children: []
    },
    {
        path: "/PromoteModule/PromoteMaterial/List",
        name: "PromoteMaterial_List",
        component: () =>
            import ("@/Views/PromoteModule/PromoteMaterial/List.vue"),
        children: []
    },
    {
        path: "/PromoteModule/PromoteMaterial/Detail",
        name: "PromoteMaterial_Detail",
        component: () =>
            import ("@/Views/PromoteModule/PromoteMaterial/Detail.vue"),
        children: []
    },
    {
        path: "/PromoteModule/PromoteMaterial/BindMaterial",
        name: "PromoteMaterial_BindMaterial",
        component: () =>
            import ("@/Views/PromoteModule/PromoteMaterial/BindMaterial.vue"),
        children: []
    }, {
        path: "/PromoteModule/PromoteMaterial/MaterialDetail",
        name: "PromoteMaterial_MaterialDetail",
        component: () =>
            import ("@/Views/PromoteModule/PromoteMaterial/MaterialDetail.vue"),
        children: []
    }
]