import Vue from "vue";
import MenuMethod from "@/Plugin/MenuMethod.js";
//import MenuDataSource from "@/Plugin/MenuData.js";
var CacheName = {
    CurrentUserInfo: "CurrentUserInfo"
}
var Store = {
}

var MenuStore = {
    MenuInit: false,
    MenuData: {},
    MemuAction: {}

}
var SetCache = function (name, value) {
    Store[name] = value;
};
var GetCache = function (name) {
    return Store[name];
}
var MenuInit = function (mdata) {
    if (MenuStore.MenuInit) return;
    //console.log("Menu Init");
    MenuStore.MenuData = mdata;//MenuDataSource.GetData();
    MenuStore.MenuData.MenuItem.forEach(function (item) {
        MenuTraverse(item, []);
    });
    MenuStore.MenuInit = true;
}

var MenuTraverse = function (item, ParentMenuKey) {
    MenuStore.MemuAction[item.Key] = {
        ActionStr: item.Action,
        Key: item.Key,
        ParentMenuKey: ParentMenuKey,
        Text: item.Text
    };
    if (item.Type == 1) {
        MenuStore.MemuAction[item.Key].Action = function () {
            Vue.global.NavigateTo(item.Action);
        }

    } else if (item.Type == 2) {
        var array = ParentMenuKey.slice();
        array.push(item.Key);
        item.SubItem.forEach(function (item2) {
            MenuTraverse(item2, array);
        });
    } else if (item.Type == 3) {
        MenuStore.MemuAction[item.Key].Action = function () {
            MenuMethod.Run(item.Action);
        }
    } else {
        console.log(item);
    }
}
var GetMenuTheme = function () {
    return MenuStore.MenuData.MenuTheme;
}
var GetMenuData = function () {
    return JSON.parse(JSON.stringify(MenuStore.MenuData));
}
var GetMenuActionData = function (key) {
    console.log(MenuStore.MemuAction);
    console.log(key);
    console.log(MenuStore.MemuAction[key]);
    return JSON.parse(JSON.stringify(MenuStore.MemuAction[key]));
}
var MenuAction = function (key) {   
    MenuStore.MemuAction[key].Action();
}
var GetCurrentMenu = function () {
    var result = {
        MenuCurrent: [],
        MenuPath: []
    };
    for (var item in MenuStore.MemuAction) {
        var ActionStr = (MenuStore.MemuAction[item].ActionStr || "").toLowerCase();
        var routeName = Vue.global.VueRoot.$route.name.toLowerCase();
        if (ActionStr == routeName) {
            result.MenuCurrent = [MenuStore.MemuAction[item].Key];
            result.MenuPath = MenuStore.MemuAction[item].ParentMenuKey.slice();
            return result;
        }
    }
    return result;
}
export default {
    CacheName,
    SetCache,
    GetCache,
    MenuInit,
    GetMenuTheme,
    GetMenuData,
    MenuAction,
    GetCurrentMenu,
    GetMenuActionData
}