
export default [
    {
        path: "/ShopModule/Order/List",
        name: "Order_OrderList",
        component: () => import("@/Views/ShopModule/Order/List.vue"),
        children: []
    },
    {
        path: "/ShopModule/Order/Detail",
        name: "Order_OrderDetail",
        component: () => import("@/Views/ShopModule/Order/Detail_Order/Detail.vue"),
        children: []
    },
    {
        path: "/ShopModule/Order/Detail_Reward",
        name: "Order_DetailReward",
        component: () => import("@/Views/ShopModule/Order/Detail_Reward/Detail_Reward.vue"),
        children: []
    },  
    {   
        path: "/ShopModule/Product/List", 
        name: "ProductList",
        component: () => import("@/Views/ShopModule/Product/List.vue"),
        children: []
    },
    {
        path: "/ShopModule/Product/Detail",
        name: "ProductDetail",
        component: () => import("@/Views/ShopModule/Product/Detail.vue"),
        children: []
    },
    {
        path:"/ShopModule/Product/SpecificationList",
        name:"SpecificationList",
        component:()=>import("@/Views/ShopModule/Product/SpecificationList.vue"),
        children:[]
    },
    {
        path: "/ShopModule/ShopManage/BaseInfor",
        name: "ShopManage_BaseInfor",
        component: () => import("@/Views/ShopModule/ShopManage/BaseInfor.vue"),
        children: []
    },
    {
        path: "/ShopModule/ShopManage/QuestionList",
        name: "ShopManage_QuestionList",
        component: () => import("@/Views/ShopModule/ShopManage/QuestionList.vue"),
        children: []
    },
    {
        path: "/ShopModule/ShopManage/QuestionDetail",
        name: "ShopManage_QuestionDetail",
        component: () => import("@/Views/ShopModule/ShopManage/QuestionDetail.vue"),
        children: []
    }
    ,
    {
        path: "/ShopModule/ShopManage/SiteList",
        name: "ShopManage_SiteList",
        component: () => import("@/Views/ShopModule/ShopManage/SiteList.vue"),
        children: []
    }
    ,
    {
        path: "/ShopModule/ShopManage/SiteDetail",
        name: "ShopManage_SiteDetail",
        component: () => import("@/Views/ShopModule/ShopManage/SiteDetail.vue"),
        children: []
    }
    ,
    {
        path: "/ShopModule/ShopManage/ShopManage",
        name: "ShopManage_ShopManage",
        component: () => import("@/Views/ShopModule/ShopManage/ShopManage.vue"),
        children: []
    }
    ,
    {
        path: "/ShopModule/TemplateMessage/List",
        name: "TemplateMessage_List",
        component: () => import("@/Views/ShopModule/TemplateMessage/List.vue"),
        children: []
    },
    {
        path: "/ShopModule/ShoppingCartOrder/List",
        name: "Order_ShoppingCart",
        component: () => import("@/Views/ShopModule/ShoppingCartOrder/List.vue"),
        children: []
    },
    {
        path: "/ShopModule/ShoppingCartOrder/Detail",
        name: "Order_ShopOrderDetail",
        component: () => import("@/Views/ShopModule/ShoppingCartOrder/Detail.vue"),
        children: []
    },


]