export default [
    {
        path: "/FinancialModule/Income/Index",
        name: "FinancialModule_Income",
        component: () => import("@/Views/FinancialModule/Income/Index.vue"),
        children: []
    },
    {
        path: "/FinancialModule/Expend/Index",
        aaa: "",
        name: "FinancialModule_Expend",
        component: () => import("@/Views/FinancialModule/Expend/Index.vue"),
        children: []
    }
]