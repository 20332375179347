export default [
    {
        path: "/Home/Index",
        name: "HomeIndex",
        component: () => import("@/Views/Home/Index.vue"),
        children: []
    },
    {
        path: "/Home/MemberInformatio",
        name: "MenberInformation",
        component: () => import("@/Views/Home/MemberInformatio.vue"),
        children: []
    }
]