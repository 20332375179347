export default [
    {
        path: "/MarketingModule/Activity/List/Index",
        name: "Activity_List",
        component: () => import("@/Views/MarketingModule/Activity/List/Index.vue"),
        children: []
    },
    {
        path: "/MarketingModule/Activity/Detail/Index",
        name: "Activity_Detail",
        component: () => import("@/Views/MarketingModule/Activity/Detail/Index.vue"),
        children: []
    },
    {
        path: "/MarketingModule/Activity/Save/Index",
        name: "Activity_Save",
        component: () => import("@/Views/MarketingModule/Activity/Save/Index.vue"),
        children: []
    },
    {
        path: "/MarketingModule/M2Activity/List",
        name: "M2Activity_List",
        component: () => import("@/Views/MarketingModule/M2Activity/List.vue"),
        children: []
    }
    ,
    {
        path: "/MarketingModule/M2Activity/Detail",
        name: "M2Activity_Detail",
        component: () => import("@/Views/MarketingModule/M2Activity/Detail.vue"),
        children: []
    },
    {
        path: "/MarketingModule/M2Activity/PrizeList",
        name: "M2Activity_PrizeList",
        component: () => import("@/Views/MarketingModule/M2Activity/PrizeList.vue"),
        children: []
    },
    {
        path: "/MarketingModule/M2Activity/PrizeDetail",
        name: "M2Activity_PrizeDetail",
        component: () => import("@/Views/MarketingModule/M2Activity/PrizeDetail.vue"),
        children: []
    },
    {
        path: "/MarketingModule/M2Activity/PrizeGoodList",
        name: "M2Activity_PrizeGoodList",
        component: () => import("@/Views/MarketingModule/M2Activity/PrizeGoodList.vue"),
        children: []
    },
    {
        path: "/MarketingModule/M2Activity/ProductList",
        name: "M2Activity_ProductList",
        component: () => import("@/Views/MarketingModule/M2Activity/ProductList.vue"),
        children: []
    },
    {
        path: "/MarketingModule/M2Activity/ReadList",
        name: "M2Activity_ReadList",
        component: () => import("@/Views/MarketingModule/M2Activity/ReadList.vue"),
        children: []
    },
    {
        path: "/MarketingModule/M2Activity/PrizeSendDetail",
        name: "M2Activity_PrizeSendDetail",
        component: () => import("@/Views/MarketingModule/M2Activity/PrizeSendDetail.vue"),
        children: []
    },
    {
        path: "/MarketingModule/M2Activity/ActivityAwardDetail",
        name: "M2Activity_ActivityAwardDetail",
        component: () => import("@/Views/MarketingModule/M2Activity/ActivityAwardDetail.vue"),
        children: []
    },
    {
        path: "/MarketingModule/M2Activity/PhoneList",
        name: "M2Activity_PhoneList",
        component: () => import("@/Views/MarketingModule/M2Activity/PhoneList.vue"),
        children: []
    },
    {
        path: "/MarketingModule/MarketVideo/List",
        name: "MarketVideo_List",
        component: () => import("@/Views/MarketingModule/MarketVideo/List.vue"),
        children: []
    },
    {
        path: "/MarketingModule/MarketVideo/Detail",
        name: "MarketVideo_Detail",
        component: () => import("@/Views/MarketingModule/MarketVideo/Detail.vue"),
        children: []
    }
]