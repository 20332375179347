import Vue from "vue"
import Router from "vue-router"
import Test_router from "@/Views/Test/router"
import Home_router from "@/Views/Home/router"
import OperationsModule_router from "@/Views/OperationsModule/router"
import ShopModule_router from "@/Views/ShopModule/router"
import TaskManageModule_router from "@/Views/TaskManageModule/router"
import PartnerModule_router from "@/Views/PartnerModule/router"
import SystemModule_router from "@/Views/SystemModule/router"
import FinancialModule_router from "@/Views/FinancialModule/router"
import MaterialSourceModule_router from "@/Views/MaterialSourceModule/router"
import MaterialModule from "@/Views/MaterialModule/router"
import Error_router from "@/ErrorPage/router"
import Marketing_router from "@/Views/MarketingModule/router"
import PromoteModule_router from "@/Views/PromoteModule/router"
import Ranking_router from "./Views/Ranking/router"
import InteractiveModel_router from "./Views/InteractiveModel/router"
import InteractionClockModule_router from "./Views/InteractionClockModule/router"

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: "/Home",
            name: "Home",
            component: () => import("@/Views/Home.vue"),
            redirect: { name: "HomeIndex" },
            children: [
                ...Test_router,
                ...Home_router,
                ...OperationsModule_router,
                ...ShopModule_router,
                ...TaskManageModule_router,
                ...PartnerModule_router,
                ...SystemModule_router,
                ...FinancialModule_router,
                ...MaterialSourceModule_router,
                ...Marketing_router,
                ...MaterialModule,
                ...PromoteModule_router,
                ...InteractiveModel_router,
                ...InteractionClockModule_router,
             
                /*...DataIinstitutions_router,*/
            ]
        }, 
        {
            path: "/DataIinstitutions/DataIndex",
            name: "DataIndex",
            component: () => import("@/Views/DataIinstitutions/DataIndex.vue"),
            children: [
                  
            ]
        },
        {
            path: "/Login",
            name: "Login",
            component: () => import("@/Views/Login.vue")
        },
        {
            path: "/Error",
            name: "Error",
            component: () => import("@/Views/Error.vue"),
            children: [...Error_router,]
        },
        {
            path: "/",
            name: "Root",
            redirect: { name: "Login" },
        },
       ...Ranking_router,
    ]
})
