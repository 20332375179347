export default [
    {
        path: "/OperationsModule/Operations/List",
        name: "Operations_List",
        component: () => import("@/Views/OperationsModule/Operations/List.vue"),
        children: []
    },
    {
        path: "/OperationsModule/Operations/Detail",
        aaa: "",
        name: "OperationsDetail",
        component: () => import("@/Views/OperationsModule/Operations/Detail.vue"),
        children: []
    },
    {
        path: "/OperationsModule/Operations/Setting",
        aaa: "",
        name: "Operations_Setting",
        component: () => import("@/Views/OperationsModule/OperationSetting/Setting.vue"),
        children: []
    },
    {
        path: "/OperationsModule/ChatWebInfor/List",
        aaa: "",
        name: "ChatWebInfor_List",
        component: () => import("@/Views/OperationsModule/ChatWebInfor/List.vue"),
        children: []
    },
    {
        path: "/OperationsModule/ChatWebInfor/Detail",
        aaa: "",
        name: "ChatWebInfor_Detail",
        component: () => import("@/Views/OperationsModule/ChatWebInfor/Detail.vue"),
        children: []
    },
    {
        path: "/OperationsModule/ChatUserPool/List",
        aaa: "",
        name: "ChatUserPool_List",
        component: () => import("@/Views/OperationsModule/ChatUserPool/List.vue"),
        children: []
    },
    {
        path: "/OperationsModule/Operations/AgentGuestLogList",
        name: "Operations_AgentGuestLogList",
        component: () => import("@/Views/OperationsModule/Operations/AgentGuestLogList.vue"),
        children: []
    },
    {
        path: "/OperationsModule/Operations/QRcodeRecordList",
        name: "Operations_QRcodeRecordList",
        component: () => import("@/Views/OperationsModule/Operations/QRcodeRecordList.vue"),
        children: []
    }
]