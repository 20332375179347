export default [
    {
        path: "/Test/UploadDemo",
        name: "UploadDemo",
        component: () => import("@/Views/Test/UploadDemo.vue")
    },
    {
        path: "/Test/ExportDemo",
        name: "ExportDemo",
        component: () => import("@/Views/Test/ExportDemo.vue")
    },
    {
        path: "/Test/Echarts",
        name: "Echarts",
        component: () => import("@/Views/Test/Echarts.vue")
    },
    {
        path: "/Test/BaiduMap",
        name: "BaiduMap",
        component: () => import("@/Views/Test/BaiduMap2.vue")
    },
    {
        path: "/Test/RichTest",
        name: "RichTest",
        component: () => import("@/Views/Test/RichTest.vue")
    },
    {
        path: "/Test/CheckBoxDemo",
        name: "CheckBoxDemo",
        component: () => import("@/Views/Test/CheckBoxDemo.vue")
    },
    {
        path: "/Test/EnumDemo",
        name: "EnumDemo",
        component: () => import("@/Views/Test/EnumDemo.vue")
    },
    {
        path: "/Test/TableDemo",
        name: "TableDemo",
        component: () => import("@/Views/Test/TableDemo.vue")
    },
    {
        path: "/Test/MessageHelperTest",
        name: "MessageHelperTest",
        component: () => import("@/Views/Test/MessageHelperTest.vue")
    }
]