import axios from 'axios'
import util from '@/Plugin/util.js'
import message from "@/Plugin/MessageHelper.js"
import Vue from 'vue'
axios.defaults.timeout = 5000; //响应时间
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'; //配置请求头
function ShowErrorMessage(option, msg) {
    if (typeof option.ErrorMessageShow == "function") {
        option.ErrorMessageShow(msg);
    } else {
        message.error(msg);
    }
}

async function Post(option) {
    if (util.isBlank(option.url))
        ShowErrorMessage(option, "请求地址不能为空");
    var op = {
        method: 'post',
        url: option.url,
        data: option.data,
        responseType: option.responseType,
        headers: {
            'Content-Type': option.ContentType == '' ? 'application/json' : option.ContentType
        },
    };
    var Success = function(response) {
        //console.log("axios Success");
        //console.log(response);
        typeof option.OnSuccess == "function" ? option.OnSuccess(response) : function() {}
    }
    var Error = function(error) {
        console.log("axios Error");
        console.log(error);
        console.log(error.response);
        console.log(error.message);
        console.log("=================");
        var response = error.response;
        if (response) {
            var status = response.status;
            if (status == 401) { //未登陆 
                Vue.global.NavigateTo("Login");
            } else if (status == 500) { //系统错误 
                if (response.data.Message) {
                    ShowErrorMessage(option, response.data.Message);
                } else {
                    ShowErrorMessage(option, response.data);
                }
            } else if (status == 501) { //自定义错误
                typeof option.OnError == "function" ? option.OnError(error) : ShowErrorMessage(option, response.data.Message);
            } else { //其他错误
                ShowErrorMessage(option, "发生错误，错误代码" + status);
            }
        } else if (error.message.indexOf("timeout") >= 0) {
            ShowErrorMessage(option, "获取数据超时");
        } else {
            ShowErrorMessage(option, error.message);
        }
        return false;
    }
    await axios(op).then(Success, Error)
        .catch(function(error) {
            console.log("axios Catch");
            console.log(error);
            message.error("系统发生错误。请查看错误日志");
        });
}
async function Get(option) {
    if (util.isBlank(option.url))
        ShowErrorMessage(option, "请求地址不能为空");
    var op = {
        method: 'get',
        url: option.url,
    };
    var Success = function(response) {
        typeof option.OnSuccess == "function" ? option.OnSuccess(response) : function() {}
    }
    var Error = function(error) {
        console.log("axios Error");
        console.log(error);
        console.log(error.response);
        console.log(error.message);
        console.log("=================");
        var response = error.response;
        if (response) {
            var status = response.status;
            if (status == 401) { //未登陆 
                Vue.global.NavigateTo("Login");
            } else if (status == 500) { //系统错误 
                if (response.data.Message) {
                    ShowErrorMessage(option, response.data.Message);
                } else {
                    ShowErrorMessage(option, response.data);
                }
            } else if (status == 501) { //自定义错误
                typeof option.OnError == "function" ? option.OnError(error) : ShowErrorMessage(option, response.data.Message);
            } else { //其他错误
                ShowErrorMessage(option, "发生错误，错误代码" + status);
            }
        } else if (error.message.indexOf("timeout") >= 0) {
            ShowErrorMessage(option, "获取数据超时");
        } else {
            ShowErrorMessage(option, error.message);
        }
        return false;
    }
    await axios(op).then(Success, Error)
        .catch(function(error) {
            console.log("axios Catch");
            console.log(error);
            message.error("系统发生错误。请查看错误日志");
        });
}

function DownLoadFile(option) {
    var op = {
        url: option.url,
        data: option.data,
        responseType: 'blob',
        OnSuccess: function(response) {
            var blob = new Blob([response.data], { type: option.ContentType })
            var downloadElement = document.createElement('a');
            var href = window.URL.createObjectURL(blob);
            downloadElement.href = href;
            downloadElement.download = option.FileName;
            document.body.appendChild(downloadElement);
            downloadElement.click();
            document.body.removeChild(downloadElement);
            window.URL.revokeObjectURL(href);

        },
        OnError: function(error) {
            let reader = new FileReader();
            reader.onload = e => {
                let res = JSON.parse(e.target.result);
                message.error(res.Message);
            };
            var blob = new Blob([error.response.data], { type: "application/json" })
            reader.readAsText(blob);
        }
    };
    Post(op);
}
export default {
    Post,
    DownLoadFile,
    Get
}