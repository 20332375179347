export default [
    {
        path: "/PartnerModule/PartnerCheck/List",
        name: "PartnerCheck_PartnerCheck",
        component: () => import("@/Views/PartnerModule/PartnerCheck/List.vue"),
        children: []
    },
    {
        path: "/PartnerModule/PartnerList/List",
        name: "PartnerList_PartnerList",
        component: () => import("@/Views/PartnerModule/PartnerList/List.vue"),
        children: []
    },
    {
        path: "/PartnerModule/PartnerList/Detail/Detail",
        name: "PartnerList_PartnerDetail",
        component: () => import("@/Views/PartnerModule/PartnerList/Detail/Detail.vue"),
        children: []
    }, {
        path: "/PartnerModule/PartnerSetting/Index",
        name: "PartnerModule_PartnerSetting",
        component: () => import("@/Views/PartnerModule/PartnerSetting/Index.vue"),
        children: []
    },
    {
        path: "/PartnerModule/Commission/List",
        name: "Commission_List",
        component: () => import("@/Views/PartnerModule/Commission/List.vue"),
        children: []
    },
    {
        path: "/PartnerModule/Commission/PartnerDetail",
        name: "Commission_PartnerDetail",
        component: () => import("@/Views/PartnerModule/Commission/PartnerDetail.vue"),
        children: []
    },
    {
        path: "/PartnerModule/Commission/PartnerAccountDetail",
        name: "Commission_PartnerAccountDetail",
        component: () => import("@/Views/PartnerModule/Commission/PartnerAccountDetail.vue"),
        children: []
    },
    {
        path: "/PartnerModule/Commission/PartnerAccountLog",
        name: "Commission_PartnerAccountLog",
        component: () => import("@/Views/PartnerModule/Commission/PartnerAccountLog.vue"),
        children: []
    },    
    {
        path: "/PartnerModule/PartnerHonor/List",
        name: "PartnerHonor_List",
        component: () => import("@/Views/PartnerModule/PartnerHonor/List.vue"),
        children: []
    },
    {
        path: "/PartnerModule/PartnerHonor/Form",
        name: "PartnerHonor_Form",
        component: () => import("@/Views/PartnerModule/PartnerHonor/Form.vue"),
        children: []
    },
    {
        path: "/PartnerModule/PartnerHonor/Detail",
        name: "PartnerHonor_Detail",
        component: () => import("@/Views/PartnerModule/PartnerHonor/Detail.vue"),
        children: []
    },
    {
        path: "/PartnerModule/PartnerHonor/TmpList",
        name: "PartnerHonor_TmpList",
        component: () => import("@/Views/PartnerModule/PartnerHonor/TmpList.vue"),
        children: []
    }
]